<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mrefund-page" v-if="refundInfo">
				<div v-if="refundInfo.refund_status == 1" class="page-section page-head">
					<template v-if="refundInfo.goods_status !== 3">
						<div class="status-item">
							<div class="item-label">{{$t('mobile.refund.sellerPending')}}</div>
							<div class="item-block">￥<span>{{ refundInfo.actual_price | priceFilter() }}</span></div>
						</div>
					</template>
					<el-steps v-else class="refund-steps" direction="vertical" :active="refundInfo.apply_refund_info.step">
						<el-step :title="$t('mobile.refund.stepTitle1')"></el-step>
						<el-step :title="$t('mobile.refund.stepTitle2')">
							<template slot="description" v-if="refundInfo.apply_refund_info.step == 1">
								<div class="step-text" v-for="item in $t('mobile.refund.stepText1')" :key="item">{{item}}</div>
								<div class="step-tips" v-for="item in $t('mobile.refund.stepTips1')" :key="item">{{item}}</div>
							</template>
						</el-step>
						<el-step :title="$t('mobile.refund.stepTitle3')">
							<template slot="description" v-if="refundInfo.apply_refund_info.step == 2">
								<div class="step-text" v-for="item in $t('mobile.refund.stepText2')" :key="item">{{item}}</div>
								<div class="step-tips" v-for="item in $t('mobile.refund.stepTips2')" :key="item">{{item}}</div>
							</template>
						</el-step>
						<el-step :title="$t('mobile.refund.stepTitle4')">
							<template slot="description" v-if="refundInfo.apply_refund_info.step == 3">
								<div class="step-text" v-for="item in $t('mobile.refund.stepText3')" :key="item">{{item}}</div>
								<div class="step-tips" v-for="item in $t('mobile.refund.stepTips3')" :key="item">{{item}}</div>
								<div class="step-logistics">
									<span>{{refundInfo.apply_refund_logistics_info.logistics_company}}</span>
									<span>{{refundInfo.apply_refund_logistics_info.logistics_no}}</span>
								</div>
							</template>
						</el-step>
						<el-step :title="$t('mobile.refund.stepTitle5')"></el-step>
					</el-steps>
				</div>
				<div v-else class="page-section page-head">
					<div class="status-item">
						<div class="item-label">{{ refundInfo.refund_status | statusFilter($t('mobile.filters.refundStatus')) }}</div>
						<div class="item-block">￥<span>{{ refundInfo.actual_price | priceFilter() }}</span></div>
					</div>
					<div class="time">{{ refundInfo.update_time | parseTime('{y}年{m}月{d}日 {h}:{i}') }}</div>
					<div class="tips" v-if="refundInfo.refund_status === 2 && refundInfo.goods_status === 1">{{$t('mobile.refund.statusTips1-1')}}￥{{ refundInfo.actual_price | priceFilter() }}{{$t('mobile.refund.statusTips1-2')}}</div>
					<div class="tips" v-if="refundInfo.refund_status === 5">{{$t('mobile.refund.statusTips2')}}</div>
				</div>
				<div class="page-section" v-if="refundInfo.refund_status == 1 && refundInfo.goods_status === 3 && this.refundInfo.apply_refund_info.step ===2">
					<div class="refund-address" v-if="refundInfo.receiving_address_info">
						<div class="name">{{$t('mobile.formItem.consignee')}}：{{refundInfo.receiving_address_info.shipaddr_name}} <span>{{refundInfo.receiving_address_info.phone_mob || refundInfo.receiving_address_info.phone_tel}}</span></div>
						<div class="text">{{refundInfo.receiving_address_info.region_name+refundInfo.receiving_address_info.address}}</div>
					</div>
					<div class="refund-number">
						<div class="item-label">
							<div class="name">{{$t('mobile.refund.logisticsTitle')}}</div>
							<div class="text">{{$t('mobile.refund.logisticsText')}}</div>
						</div>
						<div class="item-block">
							<router-link :to="{ name: 'mRefundLogistics', params:{ id: refundInfo.apply_refund_id }}" class="btn">{{$t('mobile.actions.fillInNumber')}}</router-link>
						</div>
					</div>
				</div>
				<router-link :to="{ name: 'mRefundHistory', params:{ id: refundInfo.apply_refund_id }}" class="page-section">
					<div class="section-title">
						<div class="item-label">{{$t('mobile.refund.history')}}</div>
						<div class="item-block">
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</router-link>
				<div class="page-section">
					<div class="section-title"><div class="item-label">{{ $t('mobile.refund.message') }}</div></div>
					<div class="goods-item" v-for="(goods,index) in refundInfo.order_info.order_goods" :key="index">
						<img class="item-pic" :src="goods.goods_image" alt="" @error="imageLoadError">
						<div class="item-main">
							<div class="name">{{ goods.goods_name }}</div>
							<div class="desc">{{ goods.specification }}</div>
						</div>
						<div class="item-box">
							<div class="price">￥<span>{{ goods.price }}</span></div>
							<div class="number">x{{ goods.quantity }}</div>
						</div>
					</div>
					<div class="page-layout">
						<div class="item">
							<div class="item-label">{{ $t('mobile.refund.reason') }}</div>
							<div class="item-block">{{ refundInfo.reason }}</div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.refund.money') }}</div>
							<div class="item-block">￥{{ refundInfo.actual_price | priceFilter() }}</div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.refund.applyTime') }}</div>
							<div class="item-block">{{ refundInfo.create_time | parseTime('{y}-{m}-{d}') }}</div>
						</div>
						<div class="item">
							<div class="item-label">{{ $t('mobile.refund.number') }}</div>
							<div class="item-block">{{ refundInfo.trade_no }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchRefundInfo } from "@/api/mobile/order"
export default {
	filters: {
		statusFilter(status, option) {
			return option[status]
		}
	},
	data() {
		return {
			refundInfo: null
		}
	},
	created() {
		this.getRefundInfo()
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getRefundInfo() {
			fetchRefundInfo({ apply_refund_id: this.$route.params.id }).then(response => {
				this.refundInfo = response.data || null
			}).catch(() => {
				this.$router.go(-1)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mrefund-page {
	padding: 0.32rem;
	.page-section {
		display: block;
		border-radius: 0.32rem;
		padding: 0.192rem 0.32rem;
		background-color: #ffffff;
		margin-bottom: 0.32rem;
		.section-title {
			color: #333333;
			display: flex;
			height: 1.088rem;
			line-height: 1.088rem;
			justify-content: space-between;
			.item-label {
				font-weight: bold;
				font-size: 0.448rem;
			}
			.item-block {
				color: #999999;
				font-size: 0.448rem;
			}
		}
	}
	.page-head {
		padding-top: 0.32rem;
		padding-bottom: 0.32rem;
		.status-item {
			display: flex;
			justify-content: space-between;
			color: #ff5000;
			font-weight: bold;
			line-height: 0.768rem;
			.item-label {
				font-size: 0.48rem;
				span {
					color: #333333;
					font-size: 0.348rem;
					font-weight: normal;
				}
			}
			.item-block {
				font-size: 0.384rem;
				span {
					font-size: 0.512rem;
				}
			}
		}
		.time {
			color: #999999;
			line-height: 0.544rem;
			font-size: 0.384rem;
			margin-bottom: 0.064rem;
		}
		.tips {
			font-size: 0.384rem;
			line-height: 0.544rem;
		}
		::v-deep .refund-steps {
			padding-top: 0.32rem;
			.el-step {
				.el-step__head {
					width: 0.384rem;
					.el-step__line {
						left: 0.096rem;
						width: 0.064rem;
					}
					.el-step__icon {
						width: 0.256rem;
						height: 0.256rem;
						vertical-align: top;
						font-size: 0;
						border-width: 0.016rem;
					}
					&.is-finish {
						color: #ff5000;
						border-color: #ff5000;
						.el-step__line {
							.el-step__line-inner {
								height: 100% !important;
								border-width: 0.032rem !important;
							}
						}
					}
					&.is-process {
						color: #ff5000;
						border-color: #ff5000;
						.el-step__icon {
							background-color: #ff5000;
						}
					}
				}
				.el-step__main {
					padding-left: 0.384rem;
					margin-top: -0.32rem;
					.el-step__title {
						font-size: 0.448rem;
						line-height: 0.768rem;
						&.is-finish {
							color: #333333;
						}
						&.is-process {
							color: #ff5000;
						}
						&.is-wait {
							color: #999999;
						}
					}
					.el-step__description {
						padding-right: 0;
						line-height: 0.544rem;
						padding-bottom: 0.512rem;
						color: #999999;
						.step-text {
							font-size: 0.384rem;
							margin-bottom: 0.064rem;
						}
						.step-tips {
							font-size: 0.352rem;
							margin-bottom: 0.064rem;
						}
						.step-logistics {
							padding: 0.32rem;
							border-radius: 0.16rem;
							background-color: #f0f2f5;
							font-size: 0.352rem;
							margin-top: 0.128rem;
							span {
								margin-right: 0.16rem;
							}
						}
						&.is-process {
							.step-text {
								color: #ff5000;
							}
						}
					}
				}
				&.is-vertical {
					.el-step__head {
						flex-shrink: 0;
					}
					.el-step__main {
						padding-left: 0.384rem;
					}
				}
			}
		}
	}
	.refund-address {
		padding: 0.192rem 0;
		.name {
			line-height: 0.64rem;
			font-weight: bold;
			font-size: 0.416rem;
			margin-bottom: 0.128rem;
			span {
				font-weight: normal;
				color: #999999;
				font-size: 0.384rem;
				margin-left: 0.256rem;
			}
		}
		.text {
			color: #555555;
			line-height: 0.544rem;
			font-size: 0.384rem;
			span {
				color: #ff5000;
				margin-left: 0.256rem;
			}
		}
	}
	.refund-number {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.192rem 0;
		.item-label {
			.name {
				font-size: 0.416rem;
				font-weight: bold;
				line-height: 0.64rem;
			}
			.text {
				color: #999999;
				font-size: 0.384rem;
				line-height: 0.544rem;
			}
		}
		.item-block {
			.btn {
				width: 3.2rem;
				height: 0.896rem;
				line-height: 0.896rem;
				border-radius: 0.448rem;
				border: 1px solid #777777;
				text-align: center;
				color: #777777;
				font-size: 0.416rem;
				display: block;
			}
		}
	}
	.goods-item {
		display: flex;
		margin-bottom: 0.192rem;

		.item-pic {
			flex-shrink: 0;
			width: 2.56rem;
			height: 2.56rem;
			border-radius: 0.32rem;
			object-fit: cover;
		}

		.item-main {
			flex: 1;
			width: 0;
			margin: 0 0.32rem;

			.name {
				color: #333333;
				font-size: 0.416rem;
				height: 0.768rem;
				line-height: 0.768rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: 600;
			}

			.desc {
				max-height: 1.088rem;
				line-height: 0.544rem;
				color: #999999;
				font-size: 0.416rem;
				display: -webkit-box;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				white-space: normal;
			}
		}

		.item-box {
			flex-shrink: 0;
			text-align: right;

			.price {
				height: 0.768rem;
				line-height: 0.768rem;
				color: #333333;
				font-weight: 600;
				font-size: 0.352rem;

				span {
					font-size: 0.448rem;
				}
			}

			.number {
				height: 0.544rem;
				line-height: 0.544rem;
				color: #999999;
				font-size: 0.384rem;
			}
		}
	}
	.page-layout {
		padding: 0.192rem 0;

		.item {
			display: flex;
			padding: 0.192rem 0;
			justify-content: space-between;

			.item-label {
				font-size: 0.384rem;
			}

			.item-block {
				max-width: 70%;
				color: #999999;
				font-size: 0.384rem;
				line-height: 0.576rem;
				text-align: right;

				.price {
					color: #333333;
					font-size: 0.384rem;
					margin-left: 0.32rem;
					font-weight: 600;
				}
			}
		}
	}
}
.mrefund-head {
	padding: 0.384rem;
	margin-bottom: 0.32rem;
	background: linear-gradient(to right, #ee7e32, #f25528);

	.head-status {
		border-radius: 0.32rem;
		padding: 0.384rem 0.48rem;
		background-color: #ffffff;

		.name {
			font-size: 0.448rem;
			font-weight: 600;
			line-height: 0.768rem;

			.el-icon-circle-check {
				color: #ff5000;
				margin-right: 0.16rem;
			}
		}

		.time {
			color: #999999;
			font-size: 0.416rem;
			line-height: 0.544rem;
		}
	}
}

.mrefund-item {
	margin-bottom: 0.32rem;
	background-color: #ffffff;

	.item {
		padding: 0.32rem 0.384rem;
		line-height: 0.64rem;
		border-bottom: 1px solid #e5e5e5;
		display: flex;
		justify-content: space-between;

		.item-label {
			font-size: 0.416rem;

			&.red {
				color: #ff5000;
			}
		}

		.item-block {
			font-size: 0.416rem;

			&.price {
				color: #ff5000;
				font-size: 0.384rem;

				span {
					font-size: 0.448rem;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}
}

.order-footer {
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 9;
	box-sizing: border-box;
	background-color: #ffffff;
	padding: 0.256rem 0.384rem;
	position: absolute;
	display: flex;
	justify-content: flex-end;
	box-shadow: 0 0 2px rgba(0, 0, 0, .2);

	.btn {
		color: #333333;
		font-size: 0.416rem;
		flex-shrink: 0;
		height: 0.544rem;
		line-height: 0.544rem;
		border: 1px solid #ddd;
		padding: 0.192rem 0.32rem;
		border-radius: 0.64rem;
		margin-left: 0.32rem;
	}

	.btn-red {
		color: #ff5000;
		border-color: #ff5000;
	}
}
</style>